import './styles.css';

import React, { useEffect, useState } from 'react';
import { countries, Country } from '../../../../core/Phone/CountryCodes';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { CountryAutocompleteProps } from './index.types';

const countryToFlag = (isoCode: string) =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;

export const CountryAutocomplete: React.FunctionComponent<
  CountryAutocompleteProps
> = ({ onCountrySelected, value, testIdSection, disabled }) => {
  const [country, setCountry] = useState<Country | null>(null);

  useEffect(() => {
    if (typeof onCountrySelected === 'function') {
      onCountrySelected(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (value) {
      if (!country || country.code !== value) {
        const findCountry = countries.find((item) => item.code === value);
        if (findCountry) {
          setCountry(findCountry);
        }
      }
    } else {
      setCountry(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Autocomplete
      disabled={disabled}
      autoSelect
      fullWidth
      id="country-select-demo"
      options={countries as Country[]}
      classes={{
        option: 'option',
      }}
      autoHighlight
      getOptionLabel={(option) => option.dialCode}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.code}>
          <span className="countryFlag-CountryAutocomplete">
            {countryToFlag(option.code)}
          </span>
          {option.name} ({option.code}) {option.dialCode}
        </Box>
      )}
      onChange={(e, val) => setCountry(val)}
      value={country}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              autoComplete: 'new-password', // prevent browser autocomplete
            },
            startAdornment: (
              <InputAdornment position="start">
                {countryToFlag(country?.code || '')}
              </InputAdornment>
            ),
          }}
          label="Choose a country"
          size="small"
        />
      )}
      data-testid={`${testIdSection}-recipient-countryCode-dropdown`}
    />
  );
};
